@import '../../style/variables/colors';
@import '../../style/variables/mixin';

.header {
    font-family: 'Poppins', sans-serif;
    display: flex;
    width: 100%;
    height: 4rem;
    position: fixed;
    top: 0;
    background-color: $main-white;
}

.navbar {
    @include flex-center;
    flex-grow: 1;
    justify-content: space-around;
}

.header__logo {
    font-size: 22px;
    margin-left: 6rem;
    @include flex-center;
}

.nav__ul {
    @include flex-center;
    width: 100%;
    justify-content: center;

    li {
        margin-inline: 1.4rem;
        list-style: none;

        a {
            text-decoration: none;
            color: $nav-dark;

            &:hover {
                border-bottom: 3px solid $main-button-fire;
            }
        }
    }
}

.header__contact {
    @include buttonfire;

    a {
        text-decoration: none;
        color: $main-button-fire;
    }

    &:hover {
        @include hoveredbutton;

        a {
            color: $main-white;
        }
    }
}

.menu__icon {
    display: none;
}

.closed {
    display: none;
}

@media (max-width:1200px) {
    .header {
        font-family: 'Poppins', sans-serif;
        @include flex-center;
        width: 100%;
        justify-content: space-between;
    }

    .navbar {
        width: 100%;
        height: 100vh;
        position: fixed;
        top: -100%;
        background-color: rgb(240, 111, 111);
        @include flex-center;
        justify-content: space-around;
        transition: 1s ease;
    }

    .header__contact{
        border: 3px solid $main-white;
        background-color: $main-white;
    }

    .navbar.active{
        top: 0;
        transition: 1s ease;
    }

    .nav__ul {
        @include flex-center;
        flex-direction: column;
        width: 100%;
        justify-content: center;

        li {
            margin-inline: 1.4rem;
            margin-block: 1.5rem;
            list-style: none;

            a {
                text-decoration: none;
                color: $main-white;
                font-size: 20px;

                &:hover {
                    border-bottom: 3px solid $main-button-fire;
                }
            }
        }
    }

    .menu__icon {
        display: flex;
    }

    .closed {
        display: flex;
        margin-right: 500px;
    }
}