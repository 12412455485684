@import '../../style/variables/colors';
@import '../../style/variables/mixin';

.main__section{
    min-height: 100vh;
    width: 100%;
    overflow: hidden;
    font-family: 'Poppins', sans-serif;
    background-color: $main-white;
}

.sextion__container{
    min-height: 100vh;
    align-items: center;
}

.flex__items{
    @include flex-center;
    justify-content: center;
    height: 100vh;
}

.section__text{
    margin-inline: 5.5rem;
    width: 100%;
    border-top: 3px solid $main-button-fire;
    padding: 5px;

    h1{
        font-size: 3.125rem;
    }

    p{
        font-size: 1.25rem;
        margin-top: 1rem;
        letter-spacing: 2px;
    }

    button{
        @include buttonfire;
        margin-top: 1rem;

        &:hover{
            @include hoveredbutton;

            a{
                color: $main-white;
            }
        }

        a{
            text-decoration: none;
            color: $main-button-fire;
        }
    }
}

.section__img{
    padding: 10px;
    background-color: $main-button-fire;
    width: 100%;
    margin-right: 1rem;
    
    img{
        height: 450px;
        width: 1400px;
    }
}

.bottom__section{
    width: 100%;
    padding: 2rem;
    @include flex-center;
    flex-direction: column;
}

.links{
    display: flex;
    width: 100%;
    justify-content: space-around;

    a{
        text-decoration: none;
        font-size: 23px;
        color: $nav-dark;
        border-bottom: 3px solid $main-button-fire;
        padding-bottom: .4rem;

        &:hover{
            color: $main-button-fire;
        }
    }
}

.bottom__h3{
    width: 65%;
    margin-top: 4.125rem;
    font-size: 25px;
    line-height: 2.4rem;
    color: $card-dark;
}

.buttons{
    width: 100%;
    @include flex-center;
    justify-content: center;

    button{
        margin-inline: 1.5rem;
        margin-block: 2rem;
        color: $main-button-fire;
        @include buttonfire;

        &:hover{
            @include hoveredbutton;
            color: $main-white;
        }
    }
}

@media (max-width:1200px){
    .flex__items{
        @include flex-center;
        max-width: 100%;
        justify-content: center;
        min-height: 100vh;
        flex-direction: column-reverse;
        margin-bottom: 1rem;
    }

    .section__text{
        display: flex;
        flex-direction: column;
        align-items: center;

        p{
            font-size: 30px;
        }
    }

    .section__img{
        max-width: 60%;
    }
    .links{
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: space-around;
    
        a{
            text-decoration: none;
            font-size: 25px;
            color: $nav-dark;
            border-bottom: 3px solid $main-button-fire;
            padding-bottom: .4rem;
            padding: 1rem;
    
            &:hover{
                color: $main-button-fire;
            }
        }
    }
    
}